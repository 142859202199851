<template>
  <div class="content-right rounded-3">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item active">Dashboard</li>
      </ol>
    </nav>
    <div
      class="content-body"
      id="fullHeight"
      style="min-height: calc(100vh - 135px)"
    >
      <h2 class="title2">Dashboard</h2>
      <div class="row">
        <div class="col-md-4">
          <div class="card">
            <div class="card-body">
              <div class="d-flex justify-content-between">
                <div class="text-start">
                  <h2 class="card-title">Empolyees</h2>
                  <table style="width: 100%">
                    <tbody>
                      <tr>
                        <td>Regular</td>
                        <td>{{ dashboardData.emp_typeR }}</td>
                      </tr>
                      <tr>
                        <td>Trainee</td>
                        <td>{{ dashboardData.emp_typeT }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="card-icon">
                  <i class="icon-dashboard"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="card">
            <div class="card-body">
              <div class="d-flex justify-content-between">
                <div class="text-start">
                  <h2 class="card-title">Attendance</h2>
                  <table style="width: 100%">
                    <tbody>
                      <tr>
                        <td>Online</td>
                        <td>{{ dashboardData.is_online_now }}</td>
                      </tr>
                      <tr>
                        <td>Offline</td>
                        <td>{{ dashboardData.is_offline_now }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="card-icon">
                  <i class="icon-menu-m"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="card">
            <div class="card-body">
              <div class="d-flex justify-content-between">
                <div class="text-start">
                  <h2 class="card-title">Leaves of Absence</h2>
                  <table style="width: 100%">
                    <tbody>
                      <tr>
                        <td>Approved</td>
                        <td>{{ dashboardData.emp_leaves_approve }}</td>
                      </tr>
                      <tr>
                        <td>Pending</td>
                        <td>{{ dashboardData.emp_leaves_pending }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="card-icon">
                  <i class="icon-dashboard"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-md-4">
          <div class="card">
            <div class="card-header">
                <h5>Newest Employees</h5>
            </div>
            <div class="card-body">
              <b-table
                class="table table-custom"
                :items="newEmployees"
                :fields="NewEmployeesfields"
              >
                <template v-slot:cell(name)="data">
                  {{ data.item.lastname }}, {{ data.item.firstname }}
                </template>
                <template v-slot:cell(start_date)="data">
                  {{ data.item.startdate | formatDate}}
                </template>
              </b-table>
            </div>
          </div>
        </div>
        <div class="col-md-4">
            <div class="card">
            <div class="card-header">
                <h5>Recent Attendances</h5>
            </div>
            <div class="card-body">
              <b-table
                class="table table-custom"
                :items="attendance"
                :fields="attendancefields"
              >
                <template v-slot:cell(name)="data">
                  {{ data.item.employee }}
                </template>
                <template v-slot:cell(type)="data">
                  {{ data.item.timein != null && data.item.timeout == null ? 'Time-In' : 'Time-Out' }}
                </template>
                <template v-slot:cell(time)="data">
                  {{ data.item.timein | formatTime }}
                </template>
              </b-table>
            </div>
          </div>
        </div>
        <div class="col-md-4">
            <div class="card">
            <div class="card-header">
                <h5>Recent Leaves of Absence</h5>
            </div>
            <div class="card-body">
              <b-table
                class="table table-custom"
                :items="recentLeaves"
                :fields="recentLeavesfields"
              >
                <template v-slot:cell(name)="data">
                  {{ data.item.employee }}
                </template>
                <template v-slot:cell(date)="data">
                  {{ data.item.leavefrom | formatDate}}
                </template>
              </b-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      NewEmployeesfields: [
        {
          key: "name",
          label: "Name",
        },
        {
          key: "jobposition",
          label: "Position",
        },
        {
          key: "start_date",
        },
      ],
      attendancefields: [
          {
          key: "name",
        },
        {
          key: "type",
        },
        {
          key: "time",
        },
      ],
      recentLeavesfields: [
          {
          key: "name",
        },
        {
          key: "date",
        }
      ]
    };
  },
  computed: {
    dashboardData() {
      return this.$store.state.dashboardData;
    },
    newEmployees() {
      return this.$store.state.dashboardData.emp_all_type;
    },
    attendance() {
      return this.$store.state.dashboardData.a;
    },
    recentLeaves() {
      return this.$store.state.dashboardData.emp_approved_leave;
    },
  },
  mounted() {
    this.$store.dispatch("loadNewestEmployees");
  },
};
</script>