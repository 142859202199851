import { createStore } from 'vuex'
import axios from 'axios'
import Vuex from 'vuex';
import createPersistedState from "vuex-persistedstate";
//import Cookies from "js-cookie";

import SecureLS from "secure-ls";
const ls = new SecureLS({ isCompression: false });
import { Vue } from 'vue-property-decorator';



Vue.use(Vuex);
 const store = new Vuex.Store({
  state: {
    dashboardData: null,
    employees: [],
    employeeProfile: null,
    alertMessage: '',
    newEmployeeOptions: null,
    users: [],
    roles: [],
    permissions: [],
    loader: false,
    personalDashboardData: null,
    myLeavesData: null,
    myAttendancesData: null,
    myProfileData: null,
    attandanceData: null,
    schedulesData: null,
    companiesData: [],
    departmentsData: [],
    jobTitleData: null,
    leaveTypeData: [],
    leaveGroupsData: null,
    clockData: null,
    OfflineEmployees: [],
    permittedActions: [],
    filteredLeaves: [],
    filteredEmployees: [],
    filteredMyLeaves: [],
    accessoriesData: [],
    myAccessoriesData: [],
    myFilteredAttendancesData: [],
    filteredAttendancesData: [],
    inventoryData: [],
    holidaysData: [],
    annualLeaves: [],
    getClockData: null, 
    specialWorkingDays: null,  
    categoryData: [],
    roleData: [],
    userStatus: null,
    loadingUserStatus:0,
  },
  mutations: {
    set_dashboardData(state, data) {
      state.dashboardData = data;
      state.loader = false;
    },
    set_employees(state, data) {
        state.employees = data;
        console.log('loaded employees');
        state.loader = false;
    },
    set_employeeProfile(state,data){
        state.employeeProfile = data;
        state.loader = false;
    },
    set_alertMessage(state,message){
        state.alertMessage = message;
        state.loader = false;
    },
    clearMessage(state){
        state.alertMessage = '';
    },
    set_new_employee_options(state,data){
        state.newEmployeeOptions = data;
        state.loader = false;
    },
    set_users(state,data){
        state.users = data;
        state.loader = false;
    },
    set_roles(state,data){
        state.roles = data;
        state.loader = false;
    },
    set_role_permissions(state,data){
        state.permissions = data.d;
        setTimeout(function(){
            state.loader = false;
        console.log('data set',state.permissions);
        },1000)
        
        
    },
    set_loader(state,value){
        state.loader = value;
    },
    set_personalDashboard(state,data){
        state.personalDashboardData = data;
        state.loader = false;
    },
    set_myLeaves(state, data){
        state.myLeavesData = data;
        state.loader = false;
    },
    set_myAttendances(state,data){
        state.myAttendancesData = data;
        state.loader = false;
    },
    set_myProfile(state, data){
        state.myProfileData = data;
        state.loader = false;
    },
    set_mySchedules(state, data){
        state.mySchedulesData = data;
        state.loader = false;
    },
    set_attandanceData(state, data){
        state.attandanceData = data;
        state.loader = false;
    },
    set_schedulesData(state,data){
        state.schedulesData = data;
        state.loader = false;
    },
    set_leavesData(state,data){
        state.leavesData = data;
        state.loader = false;
    },
    set_companiesData(state, data){
        state.companiesData = data.data;
        state.loader = false;
    },
    set_departmentsData(state, data){
        state.departmentsData = data.data;
        state.loader = false;
    },
    set_jobTitleData(state, data){
        state.jobTitleData = data;
        state.loader = false;
    },
    set_leaveTypeData(state, data){
        state.leaveTypeData =  data.data;
        state.loader = false;
    },
    set_leaveGroupsData(state,data){
        state.leaveGroupsData = data;
        state.loader = false;
    },
    set_clockData(state, data){
        state.clockData = data;
        state.loader = false;
    },
    set_offline_employees(state, data){
        state.OfflineEmployees = data.data;
        state.loader = false;
    },
    set_permittedActions(state, data){
        state.permittedActions = data.data;
        //console.log('permitted actions', state.permittedActions);
    },
    set_filter_leaves(state,filterString){
        state.filteredLeaves = state.leavesData.leaves.filter((leave) => leave.status == filterString);
        state.loader = false;
    },
    set_filter_employees(state,filter){
        state.filteredEmployees = state.employees.filter((emp) => emp.employmenttype == filter.replace(/^./, filter[0].toUpperCase()));
    },
    set_my_filtered_leaves(state,filter){
        state.filteredMyLeaves = state.myLeavesData.l.filter((leave) => leave.status == filter.replace(/^./, filter[0].toUpperCase()));
        state.loader = false;
    },
    set_assigned_accessories(state, data){
        state.accessoriesData = data.data;
        state.loader = false;
    },
    set_my_accessories(state,data){
        state.myAccessoriesData = data.data;
        state.loader = false;
    },
    set_my_filtered_attendancesData(state, filterString){
        if (filterString == 'late-arrival') {   
            var latestData = state.myAttendancesData.a.filter(function (attendance) { return attendance.status_timein == 'Late In' });
            state.myFilteredAttendancesData = state.myAttendancesData.a.filter(function(attendance){ return attendance.status_timein ==  'Late In'});
            
        }
        else{
            state.myFilteredAttendancesData = state.myAttendancesData.a.filter(function(attendance){ return attendance.status_timeout ==  'Early Out'});
        }
        state.loader = false;
    },
    set_filter_attendance(state,filterString){
        if(filterString == 'late-in'){
            state.filteredAttendancesData = state.attandanceData.data.filter(function(attendance){  return attendance.status_timein ==  'Late In'});
            
        }
        else{
            state.filteredAttendancesData = state.attandanceData.data.filter(function(attendance){ return attendance.status_timeout ==  'Early Out'});
        }
        state.loader = false;
    },
    set_inventory(state,data){
        state.inventoryData = data;
    },
    set_holidays(state,data){
        state.holidaysData = data;
        state.loader = false;
    },
    set_annualleaves(state, data){
        state.annualLeaves = data;
        state.loader = false;
      },
      set_clockgetdata(state, data) {
          state.getClockData = data;
          state.loader = false;
      },
      set_specialworkingdays(state, data) {
          state.specialWorkingDays = data;
          state.loader = false;
      },
      set_category(state, data) {
          state.categoryData = data;
          state.loader = false;
      },
      set_roledata(state, data) {
        state.roleData = data;
        state.loader = false;
      },

      set_user_status(state, data) {
          state.userStatus = data;
          state.loader = false;
      },

      set_loading_status(state, data) {
          state.loadingUserStatus = data;
          state.loader = false;
      },


    },
     actions: {
         loadRoleData({ commit }) {
            
            axios.get('/validatedata/' + localStorage.getItem('role_id')).then(({ data }) => {
                commit('set_roledata', data.data)
            })
    },
    loadCategoryData({ commit }) {
        axios.get('/accessories/categorydata')
            .then(({ data }) => {
            commit('set_category', data.data);
        })
    },
        
    loadUserStatus({ commit }) {
        commit('set_loading_status', 1);
        axios.get('check-user-status')
            .then((data) => {
            commit('set_loading_status', 0);
            commit('set_user_status', data.data.response)               
        })
    },
         
    loadHolidaysdata({commit}){
        axios.get('/holidays')
            .then(({ data }) => {
                commit('set_holidays',data.data);
            })
            .catch(
                function (error) {
                  if(error.response.status == 401){
                    axios.get('/holidays')
                    .then(({data}) => {
                        commit('set_holidays',data.data);
                    })
                  }
                }
            )
      },
      loadSpecialWorkingDays({commit}) {
          axios.get('getspecialworkingdaydata')
              .then((data) => {
                  commit('set_specialworkingdays', data.data);
              })
          .catch(
                function (error) {
                  if(error.response.status == 401){
                    axios.get('getspecialworkingdaydata')
                    .then(({data}) => {
                        commit('set_specialworkingdays',data.data);
                    })
                  }
                }
            )
      },
      loadClockGetData({commit}) {
          axios.get('getdata')
              .then(({ data }) => {              
              commit('set_clockgetdata', data.data);
          })
          .catch(
              function (error){
                  if (error.response.status == 401) {
                      axios.get('getdata')
                          .then((data) => {
                              commit('set_clockgetdata', data.data);
                      })
                  }
                
            }
          )
    },
    loadAnnualLeaves({commit}){
        axios.get('annual-leaves')
        .then(({data}) => {
            commit('set_annualleaves', data.annual_leaves);
        })
        .catch(
            function(error){
                if(error.response.status == 401){
                    axios.get('annual-leaves')
                    .then(({data}) => {
                        commit('set_annualleaves', data.data);
                    })
                }
            }
        )
    },
    loadInventory({commit}){
        axios.get('/inventory')
            .then(({data}) => {
                commit('set_inventory',data.data);
            })
            .catch(
                function (error) {
                  if(error.response.status == 401){
                    axios.get('/inventory')
                    .then(({data}) => {
                        commit('set_inventory',data.data);
                    })
                  }
                }
            )
    },
    loadMyFilteredAttendances({commit},filterString){
        commit('set_my_filtered_attendancesData',filterString);
    },
    loadMyAccessories({commit}){
        axios.get('/my-accessories')
            .then(({data}) => {
                commit('set_my_accessories',data);
            })
            .catch(
                function (error) {
                  if(error.response.status == 401){
                    axios.get('/accessories')
                    .then(({data}) => {
                        commit('set_assigned_accessories',data);
                    })
                  }
                }
              )
    },
    loadAssignedAccessories({commit}){
        axios.get('/accessories')
            .then(({data}) => {
                commit('set_assigned_accessories',data);
            })
            .catch(
                function (error) {
                  if(error.response.status == 401){
                    axios.get('/accessories')
                    .then(({data}) => {
                        commit('set_assigned_accessories',data);
                    })
                  }
                }
              )
    },
    loadMyFilteredLeaves({commit},filterString){
        commit('set_my_filtered_leaves',filterString);
    },
    loadFilterEmployees({commit},filterString){
        commit('set_filter_employees',filterString);
    },
    loadFilterLeavesdata({commit},filterString){
        commit('set_filter_leaves',filterString);
    },
    loadFilterAttendancedata({commit},searchString){
        commit('set_filter_attendance',searchString);
    },
    loadPermissions({commit}){
        var role_id = localStorage.getItem('role_id');
        axios.get('permissions/'+role_id)
        .then(({ data }) => {
            commit('set_permittedActions', data)
        })
    },
    loadNewestEmployees({ commit }) {
        require('../axios');
          axios.get('dashboard')
              .then(({ data }) => {
                  commit('set_dashboardData', data)
              })
  },
    loadEmployees({ commit }){
            axios.get('employees-data')
            .then(({ data }) => {
                commit('set_employees', data.data);
            })
    },
    loadEmployeeProfile({commit},id){
            axios.get('profile/view/'+id)
            .then(({ data }) => {
                commit('set_employeeProfile', data);
            })
    },
    deleteProfile({commit},id){
        axios.get('profile/delete/'+id)
        console.log(commit);
    },
    archiveProfile({commit},id){
        axios.post('profile/archive/',{'id':id})
        console.log(commit);
    },
    loadNewEmployeeOptions({commit}){
        axios.get('/employee-form-options')
            .then(({ data }) => {
                commit('set_new_employee_options', data);
            })
    },
    clearAlertMessage({commit}){
        commit('clearMessage');
    },
    loadUsers({ commit }){
        axios.get('users')
            .then(({ data }) => {
                commit('set_users', data);
            })
    },
    deleteUser({commit},id){
        axios.get('users/delete/'+id)
            .then(({ data }) => {
                console.log(commit,data);
            })
    },
    loadRoles({commit}){
        axios.get('users/roles')
        .then(({data}) => {
            commit('set_roles',data)
        })
    },
    deleteRole({commit},id){
        axios.get('users/roles/delete/'+id)
        .then(({data}) => {
            console.log(commit,data);
        })
    },
    loadRolePermissions({commit},role_id){
        axios.get('users/roles/permissions/edit/'+role_id)
            .then(({data}) => {
                commit('set_role_permissions',data);
            })
            
    },
    switchLoader({commit},value){
        commit('set_loader',value)
    },
    loadPersonalDashboard({commit}){
        axios.get('/personal/dashboard-data')
            .then(({data}) =>{
                commit('set_personalDashboard', data);
            })
            .catch(
                function (error) {
                  if(error.response.status == 401){
                    window.location.reload();
                  }
                }
              )
    },
    loadMyLeaves({commit}){
        axios.get('/personal/leaves-data')
            .then(({data}) => {
                commit('set_myLeaves',data);
            })
    },
    loadMyattendances({commit}){
        axios.get('/personal/my-attendancedata')
            .then(({data}) => {
                commit('set_myAttendances',data);
            })
    },
    loadMyProfile({commit}){
        axios.get('/personal/my-profiledata')
            .then(({data}) =>{
                commit('set_myProfile',data);
            })
    },
    loadMySchedules({commit}){
        axios.get('/personal/my-schedulesdata')
            .then(({data}) => {
                commit('set_mySchedules', data);
            })
    },
    loadAttendancesdata({commit}){
        axios.get('/attendance-data')
            .then(({data}) => {
                commit('set_attandanceData',data);
            })
    },
    loadSchedulesdata({commit}){
        axios.get('/schedules-data')
            .then(({data}) => {
                commit('set_schedulesData', data);
            })
    },
    loadLeavesdata({commit}){
        axios.get('/leaves-data')
            .then(({ data }) => {
                console.log(data);
                commit('set_leavesData',data);
            })
    },
    loadCompanies({commit}){
        axios.get('/fields/company')
            .then(({data}) =>{
                commit('set_companiesData',data);
            })
    },
    loadDepartments({commit}){
        axios.get('/fields/department')
            .then(({data}) =>{
                commit('set_departmentsData',data);
            })
    },
    loadJobTitleData({commit}){
        axios.get('/fields/jobtitle')
            .then(({data}) =>{
                commit('set_jobTitleData', data);
            })
    },
    loadLeaveTypeData({commit}){
        axios.get('/fields/leavetype')
            .then(({data}) =>{
                commit('set_leaveTypeData', data);
            })
    },
    loadLeaveGroupsData({commit}){
        axios.get('/fields/leavetype/leave-groups')
            .then(({data}) =>{
                commit('set_leaveGroupsData',data)
            })
    },
    loadClockData({commit}){
        axios.get('clock')
            .then(({data}) => {
                commit('set_clockData', data)
            })
    },
    loadOfflineEmployees({commit}){
        axios.get('offline-employees')
            .then(({data}) => {
                commit('set_offline_employees', data)
            })
    }
  },
  modules: {
    
     },
     /* plugins: [createPersistedState()] */
    /* plugins: [
        createPersistedState({
        storage: {
            getItem: (key) => Cookies.get(key),
            setItem: (key, value) =>
            Cookies.set(key, value, { expires: 3, secure: true }),
            removeItem: (key) => Cookies.remove(key)
        }
        })
    ], */
    plugins: [
    createPersistedState({
      storage: {
        getItem: (key) => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: (key) => ls.remove(key)
      }
    })
  ],
  
})
export default store
