import VueRouter from 'vue-router';
import routes from './routes';
import store from '../store/index'
import axios from 'axios';
require('../axios');

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {  

  if (to.name == 'login' && localStorage.getItem('token' == null)) {
    next()
  } 
  else {
    if (localStorage.getItem('token') != null) {
    store.dispatch('loadUserStatus');
    var userInterval = setInterval(function () { 
      if (checkLoadingStatus() == false) {
        clearInterval(userInterval);        
        if (store.state.userStatus == 'inactive') { 
          router.push('/login');
        } else { next() }
      }      
    }, 100) 
    }
    next();         
  } 
  
}) 

function checkLoadingStatus() {  
  if (store.state.loadingUserStatus == 0) { return false; }
  else { return true; }
}
router.afterEach(to => {
  
  window.addEventListener('beforeunload', function (event) {    
      localStorage.setItem('last_visited', to.name);
      if(to.params.id){
        localStorage.setItem('last_params', to.params.id);
      }
      if(to.params.filterString){
        localStorage.setItem('last_params', to.params.filterString);
      }
  })

  if(localStorage.getItem('last_visited') != ''){
    if (localStorage.getItem('last_params') != '') {
      this.router.resolve({name: localStorage.getItem('last_visited'), params: {id: localStorage.getItem('last_params')} });
      //this.router.push({ name: localStorage.getItem('last_visited'), params: { id: localStorage.getItem('last_params') } });
    }
    else{
      this.router.resolve(localStorage.getItem('last_visited'));
    }
  }  

});

 

export default router;
