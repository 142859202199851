import Vue from 'vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import VueMeta from 'vue-meta';
import store from './store';
import Dashboard from "./pages/Dashboard";
import Vuex from 'vuex';
import router from './Router/index';
import VueRouter from 'vue-router'
import moment from 'moment';
import FlashMessage from '@smartweb/vue-flash-message';
import Vuelidate from 'vuelidate'
import loader from "vue-ui-preloader";
import VueTimepicker from 'vue2-timepicker';
import Vuetify from 'vuetify';
import App from './App';
import VueClock from '@dangvanthanh/vue-clock';

Vue.use(VueClock);


Vue.config.productionTip = false

require('./bootstrap');

// window.Vue = require('vue');
window.Vue = Vue;
Vue.use(Vuex)
Vue.use(BootstrapVue)
Vue.use(VueMeta, {
    // optional pluginOptions
    refreshOnceOnNavigation: true
})
Vue.use(Vuetify)
Vue.use(Vuelidate)
Vue.use(loader)
Vue.use(moment);
Vue.use(VueTimepicker);

/** 
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))


/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */
Vue.use(VueRouter);
Vue.use(FlashMessage);

//Vue.component('auth', require('./layouts/Auth'))

Vue.filter('formatDate', function(value) {
    if (value) {
      return moment(String(value)).format('MMM DD YYYY')
    }
  });
Vue.filter('formatTime', function(value) {
    if (value) {
      return moment(String(value)).format('h:mm:ss A');
    }
  });
 Vue.prototype.$baseUrl = 'https://hrmsapp-api.gnetserver.com/'
 //Vue.prototype.$baseUrl = 'http://localhost/HRMS-backend/public'
const app = new Vue({
    el: '#app',
    router,
    store: store,
    metaInfo: {
        // if no subcomponents specify a metaInfo.title, this title will be used
        title: 'Employee Portal',
        // all titles will be injected into this template
        titleTemplate: '%s | Employee Portal'
  },
    beforeCreate() {
       this.$store.dispatch('loadRoleData')
       //this.$store.dispatch('loadUserStatus')
    },
    methods:{
      checkAuth() {
        var token = localStorage.getItem('token');
        var role_id = localStorage.getItem('role_id');
        this.$store.dispatch('loadPermissions');

        //var userStatus = this.$store.state.userStatus;
        //console.log('main', userStatus);
        
        var loginData = this.$store.state.roleData;
        if (loginData != '') {        
          if (loginData == '0') {
            this.$router.push('/login');
          }
          else if (loginData == role_id) {
            if (token != '' && token != null && token.length > 0) {
              if (role_id == 7 && token != '') {
                if (localStorage.getItem('last_visited') != '' && localStorage.getItem('last_visited') != null) {
                  
                  if (localStorage.getItem('last_params') != '' && localStorage.getItem('last_params') != null) {
                    if (/^\d+$/.test(localStorage.getItem('last_params'))) {
                      this.$router.push({ name: localStorage.getItem('last_visited'), params: { id: localStorage.getItem('last_params') } });
                    }
                    else {
                      var newTab = this.$router.resolve({ name: localStorage.getItem('last_visited'), params: { filterString: localStorage.getItem('last_params') } });
                      window.open(newTab.href, '_blank');
                    }
                    //this.$router.push({name: localStorage.getItem('last_visited'), params: {id: localStorage.getItem('last_params')} });
                  }
                  else {
                    this.$router.push({ name: localStorage.getItem('last_visited') });
                  }
                }
                else {
                  this.$router.push('/personal/dashboard');
                }
              }
              else if (role_id != 7 && token != '') {
               
                 if (localStorage.getItem('last_visited') != '' && localStorage.getItem('last_visited') != null) {
                  if (localStorage.getItem('last_params') != '' && localStorage.getItem('last_params') != null) {
                    if (/^\d+$/.test(localStorage.getItem('last_params'))) {
                      this.$router.push({ name: localStorage.getItem('last_visited'), params: { id: localStorage.getItem('last_params') } });
                    }
                    else {
                      let routeData = this.$router.resolve({ name: localStorage.getItem('last_visited'), params: { filterString: localStorage.getItem('last_params') } });
                      window.open(routeData.href, '_blank');
                      //this.$router.push({ name: localStorage.getItem('last_visited'), params: { filterString: localStorage.getItem('last_params') } }); 
                    }
                  }
                  else {
                    this.$router.push({ name: localStorage.getItem('last_visited') });
                  }
                }
                else {
                  this.$router.push('/dashboard');
                }
                
                
              }else {
                this.$router.push('/login')
              }
            }else {
              this.$router.push('/login')
            }
          }else if (loginData != role_id) {
              this.$router.push('/login')
          }
        } else {
          window.reload();
        }    
    },
    },
  created() {
    this.checkAuth();   
    },
    render: h => h(App)
});