<template>
  <div id="wrapper">
    <div class="vertical-align-wrap">
      <div class="vertical-align-middle">
        <div class="auth-box">
          <div class="content">
            <div class="header">
              <div class="logo align-center">
                <img src="/images/img/logo.png" alt="Devoint" />
              </div>
              <p class="lead">Sign in to your account</p>
            </div>
            <form
              @submit.prevent="onSubmit"
              class="form-auth-small ui form"
              method="POST"
            >
              <div class="fields form-group">
                <div class="sixteen wide field">
                  <label for="email" class="color-white">Email</label>
                  <input
                    id="email"
                    type="email"
                    class="form-control"
                    v-model="form.email"
                    placeholder="Enter your e-mail address"
                    autofocus
                  />

                  <span class="error" v-if="$v.form.email.$error == true"
                    >Please enter valid Email!
                  </span>
                </div>
              </div>
              <div class="fields form-group">
                <div class="sixteen wide field">
                  <label for="password" class="color-white">Password</label>
                  <input
                    id="password"
                    type="password"
                    v-model="form.password"
                    class="form-control"
                    placeholder="Enter your password"
                  />
                  <span class="error" v-if="$v.form.password.$error == true"
                    >Password is required!
                  </span>
                  <span class="error" v-if="errors != null">{{
                    this.errors
                  }}</span>
                </div>
              </div>
              <!-- <div class="fields">
                                <div class="sixteen wide field">
                                    <div class="ui checkbox">
                                        <input type="checkbox" name="remember" >
                                        <label class="color-white">{{ __('Remember me') }}</label>
                                    </div>
                                </div>
                            </div> -->
              <button type="submit" class="btn btn-primary cust-button">
                SIGN IN
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.cust-button {
  width: 100%;
  color: #fff;
}
</style>
<script>
//import Header from './auth/Header.vue'
//import Footer from './auth/Footer.vue'
import { required } from "vuelidate/lib/validators";
import axios from "axios";
require("../axios");
export default {
  //components: { Header, Footer }
  data() {
    return {
      form: {
        email: null,
        password: null,
      },
      errors: null,
    };
  },
  validations: {
    form: {
      email: {
        required,
      },
      password: {
        required,
      },
    },
  },
  created() {
    /* if(vuexData != null){
      console.log("vuex", localStorage.getItem('vuex'));
    }else{
      window.location.reload();
    }     */
    //window.location.reload();
    // this.checkAuth();
  },
  methods: {
    checkAuth() {
      var token = localStorage.getItem("token");
      var role_id = localStorage.getItem("role_id");
      if (token != "" && token != null) {
        if (role_id == 7 && token != "") {
          this.$router.push("/personal/dashboard");
        } else if (role_id != 7 && token != "") {
          this.$router.push("/dashboard");
        } else {
          this.$router.push("/permission-denied");
        }
      }
    },
    onSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        console.log("error", this.$v);
        return;
      }
      axios
        .post("login", this.form)
        .then((response) => {
          localStorage.setItem("token", response.data.success.token);
          localStorage.setItem("role_id", response.data.success.user.role_id);
          localStorage.setItem(
            "name",
            response.data.success.user.name.replace(/,/g, " ")
          );
          localStorage.setItem("avatar", response.data.success.profile.avatar);
          localStorage.setItem("role_id", response.data.success.user.role_id);
          this.$store.dispatch("loadPermissions");
          if (response.data.success.user.role_id == "7") {
            this.$router.push("/personal/dashboard");
          } else {
            this.$router.push("/dashboard");
          }
        })
        .catch((error) => {
          console.log(error.response.status);
          if (error.response.status == 401) {
            this.errors = "Login failed! Email/Password is incorrect";
          } else if (error.response.status == 403) {
            this.errors = error.response.data.error;
          }
        });
    },
  },
};
</script>
