
const PersonalLayout = () =>
    import ("../layouts/personal/PersonalLayout");
const Dashboard = () =>
    import ("../layouts/Dashboard");
const DashboardComponent = () =>
    import ("../components/Dashboard");
const Employees = () =>
    import ("../pages/Employees");
const EmployeeProfile = () =>
    import ("../components/EmployeeProfile"); 
const EditEmployeeProfile = () =>
    import ("../components/EditEmployeeProfile");
const NewEmployee = () => 
    import ("../components/NewEmployee");
const Attendances = () =>
    import ("../pages/Attendances");
const AddAttendance = () =>
    import ("../components/AddAttendance");
const EditAttendance = () =>
    import ("../components/EditAttandence")

const Schedules = () =>
    import ("../pages/Schedules");
const AddSchedule = () =>
    import ("../components/AddSchedule");
const EditSchedule = ()  =>
    import ("../components/EditSchedule");

const Leaves = () =>
    import ("../pages/Leaves");
const EditLeave = () =>
    import ("../components/EditLeave");

const Companies = () =>
    import ("../pages/Companies");
const Department = () =>
    import ("../pages/Departments");
const JobTitle = () =>
    import ("../pages/JobTitle");
const LeaveTypes = () =>
    import ("../pages/LeaveTypes");
const LeaveGroups = () =>
    import ("../pages/LeaveGroups");


const Reports = () =>
    import ("../pages/Reports");
const Users = () =>
    import ("../pages/Users");
const NewUser = () =>
    import ("../components/NewUser")
const EditUser = () =>
    import ("../components/EditUser")
const Roles = () =>
    import ("../components/roles/Roles")
const NewRole = () =>
    import ("../components/roles/NewRole");    
const EditRole = () =>
    import ("../components/roles/EditRole");
const EditPermissions = () =>
    import ("../components/roles/EditPermissions");
const Settings = () =>
    import ("../pages/Settings");

const PersonalDashboard = () =>
    import ("../pages/personal/Dashboard");
const MyLeaves = () =>
    import ("../pages/personal/MyLeaves");
const MyFilteredLeaves = () =>
    import("../components/personal/MyFilteredLeaves");
const RequestLeaves = () =>
    import ("../components/personal/RequestLeave");
const MyAttendances = () =>
    import("../pages/personal/MyAttendances");
const MyHolidays = () =>
    import('../pages/personal/MyHolidays');
const filterMyattendance = () =>
    import("../components/personal/filterMyattendance");
const MyProfile = () =>
    import ("../pages/personal/MyProfile");
const EditMyProfile = () =>
    import ("../components/personal/EditMyProfile")
const EditMyLeave = () =>
    import("../components/personal/EditMyLeave");
const MySchedule = () =>
    import ("../pages/personal/MySchedule")
const MyAccessories = () =>
    import("../pages/personal/MyAccessories");
const Auth = () =>
    import ("../layouts/Auth");
const ClockLayout = () =>
    import ("../layouts/ClockLayout");
const Clock = () =>
    import ("../components/Clock");
const UnauthoriseAccess = () => 
    import("../pages/UnauthoriseAccess");
const AdminProfile = () =>
    import("../pages/MyProfile");
const EditAdminProfile = () => 
    import("../components/EditAdminProfile");
const OfflineEmployees = () =>
    import("../components/OfflineEmployees");
const FilterLeaves = () =>
    import("../components/FilterLeaves");
const FilteredEmployees = () =>
    import("../components/FilteredEmployees");
const Accessories = () =>
    import("../components/Accessories");
const AssignAccessory = () =>
    import("../components/AssignAccessory");
const AddMySchedule = () => 
    import("../components/personal/AddSchedule");
const EditMyschedule = () => 
    import("../components/personal/EditSchedule");
const FilterAttendance = () =>
    import("../components/FilterAttendance");
const Inventory = () =>
    import("../components/Inventory");
const AddItem = () =>
    import("../components/AddItem");
const EditItem = () =>
    import("../components/EditItem");
const ListOfHolidays = () =>
    import("../pages/ListOfHolidays");
const AddHoliday = () =>
    import("../components/AddHoliday");
const EditHoliday  = () =>
    import("../components/EditHoliday");
const AnnualLeaves = () =>
    import("../pages/AnnualLeaves");
const EditAnnualLeaves = () =>
    import("../components/EditAnnualLeaves");
const SpecialWorkingDays = () =>
    import("../pages/SpecialWorkingDays");
const AddSpecialWorkingDays = () =>
    import("../components/AddSpecialWorkingDays");
const UpdateSpecialWorkingDays = () =>
    import("../components/UpdateSpecialWorkingDays");
const SpecialWorking = () =>
    import('../pages/personal/SpecialWorking');
const AccessoriesCategory = () =>
    import("../components/AccessoriesCategory");
const AddAccessory = () =>
    import("../components/AddAccessory");
const Field = () =>
    import("../components/Field");
const AddFields = () =>
    import("../components/AddFields");

import Login from '../layouts/Auth';

const routes = [
    {
        path: '/login',
        component: Auth,
        name: 'login'
    },
    {
        path: 'permission-denied',
        name: 'permission-denied',
        component: UnauthoriseAccess
    },
    {
        path: "/",
        component: Dashboard,
        children: [
            {
                path: '',
                name: 'home',
                redirect: { name: 'login'}
            },
            {
                path:"dashboard",
                name: "dashboard",
                component: DashboardComponent
            },
        ]
    },
    {
        path:"/employees",
        component: Dashboard,
        children: [
            {
                path: "",
                name: "employees",
                component: Employees
            },
            {
                path: "employees/:filterString",
                name: "filtered-employee",
                component: FilteredEmployees
            },   
            {
                path:"view/:id",
                name: "employee-profile",
                component: EmployeeProfile
            },
            {
                path:"edit/:id",
                name: "edit-employee",
                component: EditEmployeeProfile
            },
            {
                path: "new",
                name: "new-employee",
                component: NewEmployee
            }
        ]
    },
    {
        path: "/holidays",
        component: Dashboard,
        children: [
            {
                path: "",
                name: "holidays",
                component: ListOfHolidays
            },
            {
                path: "add",
                name: "add-holiday",
                component: AddHoliday
            },
            {
                path: "edit/:id",
                name: "holiday-update",
                component: EditHoliday
            }
        ]
    },
    {
        path: "/my-profile",
        component: Dashboard,
        children: [
            {
                path: "",
                name: "admin-profile",
                component: AdminProfile
            },
            {
                path: "edit",
                name: "edit-admin-my-profile",
                component: EditAdminProfile
            }
        ]
    },
    {
        path: "/offline-employees",
        component: Dashboard,
        children: [
            {
                path: '',
                name: "offline-employees",
                component: OfflineEmployees
            }
        ]
    },
    {
        path: "/users",
        component: Dashboard,
        children: [
            {
                path: "",
                name: "users",
                component: Users
            },
            {
                path: "new",
                name: "new-user",
                component: NewUser
            },
            {
                path: "edit-user/:id",
                name: "edit-user",
                component: EditUser
            },
            {
                path: "roles",
                name: "roles",
                component: Roles,
            },
            {
                path: "roles/add-role",
                name: "add-role",
                component: NewRole
            },
            {
                path: "roles/edit-role/:id",
                name: "edit-role",
                component: EditRole
            },
            {
                path: "roles/permissions/edit/:id",
                name: "edit-permissions",
                component: EditPermissions
            }
        ]
    },
    {
        path: "/personal",
        component: Dashboard,
        children: [
            {
                path: "",
                redirect: { name:  'personal-dashboard'},
                component: PersonalDashboard
            },
            {
                path: "dashboard",
                name: "personal-dashboard",
                component: PersonalDashboard
            },
            {
                path: "special-working-days",
                name: "special-working-days",
                component: SpecialWorking
            },
            {
                path: "my-leaves",
                name: "my-leaves",
                component: MyLeaves
            },
            {
                path: "edit-leave/:id",
                name: "edit-leave",
                component: EditMyLeave
            },
            {
                path: 'my-leaves/:filterString',
                name: 'my-filtered-leaves',
                component: MyFilteredLeaves
            },
            {
                path: "leaves/request",
                name: "request-leave",
                component: RequestLeaves
            },
            {
                path: "my-attendances",
                name: "my-attendances",
                component: MyAttendances
            },
            {
                path: "filter-myattendance/:filterString",
                name: "filter-myattendance",
                component: filterMyattendance
            },
            {
                path: "my-profile",
                name: "my-profile",
                component: MyProfile
            },
            {
                path: "edit-my-profile",
                name: "edit-my-profile",
                component: EditMyProfile
            },
            {
                path: "my-schedules",
                name: "my-schedules",
                component: MySchedule
            },
            {
                path: "add-schedule",
                name: "add-schedule",
                component: AddMySchedule
            },
            {
                path: "edit-my-schedule/:id",
                name: "edit-my-schedule",
                component: EditMyschedule
            },
            {
                path: "my-accessories",
                name: "my-accessories",
                component: MyAccessories
            },
            {
                path: "my-holidays",
                name: "my-holidays",
                component: MyHolidays,
            },
            
        ]
    },
    {
        path: "/attendances",
        component: Dashboard,
        children: [
            {
                path: "",
                name: "attendances",
                component: Attendances
            },
            {
                path: "add-manually",
                name: "add-manually",
                component: AddAttendance
            },
            {
                path: "update/:id",
                name: 'attendance-update',
                component: EditAttendance
            },
            {
                path: ":filterString",
                name: "attendance-filter",
                component: FilterAttendance
            },
        ]
    },
    {
        path: "/schedules",
        component: Dashboard,
        children: [
            {
                path: "",
                name: "schedules",
                component: Schedules
            },
            {
                path: "add-schedule",
                name: "add-schedule",
                component: AddSchedule
            },
            {
                path: "update/:id",
                name: "schedule-update",
                component: EditSchedule
            }
        ]
    },
    {
        path: "/leaves",
        component: Dashboard,
        children: [
            {
                path: "",
                name: "leaves",
                component: Leaves
            },
            {
                path: "leaves/:filterString",
                name: "leave-filter",
                component: FilterLeaves
            },
            {
                path: "update/:id",
                name: "leave-update",
                component: EditLeave
            }
        ]
    },
    {
        path: "/Special-Working-Days",
        component: Dashboard,
        children: [
            {
                path: "",
                name: "SpecialWorkingDays",
                component: SpecialWorkingDays
            },
            {
                path: "/add",
                name: "AddSpecialWorkingDays",
                component: AddSpecialWorkingDays
            },
            {
                path: '/update',
                name: "UpdateSpecialWorkingDays",
                component: UpdateSpecialWorkingDays
            }
        ]

    },
    {
        path: "/monthly-leaves",
        component: Dashboard,
        children: [
            {
                path: "",
                name: "MonthlyLeaves",
                component: AnnualLeaves
            },
            {
                path: "edit-annual-leaves",
                name: "edit-annual-leaves",
                component: EditAnnualLeaves,
            }

        ]
    },
    {
        path: "/field",
        component: Dashboard,
        children:[
            {
                path: "",
                name: "field",
                component: Field
            },
            {
                path: "add-field",
                name: "add-field",
                component: AddFields
            },
            {
                path: "add-accessory",
                name: "add-accessory",
                component: AddAccessory
            },
            {
                path: "assign-accessory",
                name: "assign-accessory",
                component: AssignAccessory
            }
        ]
    },
    {
        path: "/accessories",
        component: Dashboard,
        children:[
            {
                path: "",
                name: "accessories",
                component: Accessories
            },
            {
                path: "accessories-category",
                name: "accessories-category",
                component: AccessoriesCategory
            },
            {
                path: "add-accessory",
                name: "add-accessory",
                component: AddAccessory
            },
            {
                path: "assign-accessory",
                name: "assign-accessory",
                component: AssignAccessory
            }
        ]
    },
    {
        path: "/inventory",
        component: Dashboard,
        children: [
            {
                path: "",
                name: "inventory",
                component: Inventory
            },
            {
                path: "add-item",
                name: "add-item",
                component: AddItem
            },
            {
                path: "edit-item/:id",
                name: "edit-inventory-item",
                component: EditItem
            }
        ]
    },
    {
        path: '/companies',
        component: Dashboard,
        children: [
            {
                path: '',
                name: 'companies',
                component: Companies
            }
        ]
    },
    {
        path: '/departments',
        component: Dashboard,
        children: [
            {
                path: '',
                name: 'departments',
                component: Department
            }
        ]
    },
    {
        path: '/job-title',
        component: Dashboard,
        children: [
            {
                path: '',
                name: 'job-title',
                component: JobTitle
            }
        ]
    },
    {
        path: '/leave-type',
        component: Dashboard,
        children: [
            {
                path: '',
                name: 'leave-type',
                component: LeaveTypes
            }
        ]
    },
    {
        path: '/leave-groups',
        component: Dashboard,
        children: [
            {
                path: '',
                name: 'leave-groups',
                component: LeaveGroups
            }
        ]
    },
    {
        path: '/clock',
        component: ClockLayout,
        children: [
            {
                path: '',
                name: 'clock',
                component: Clock
            }
        ]
    }
  
];


export default routes;